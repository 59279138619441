//画面サイズごとの処理
var w,h,r;

$(window).on('load resize', function(){
	w = $(window).width();
	h = $(window).height(); 
	r = h/w *100;
	if(r < 56.25){
		$(".cake-line svg").css({"width":"100%","height":"auto"});
	}else{
		$(".cake-line svg").css({"width":"auto","height":"100%"});
	}
	
	deviceFunc(w);
});

const browser=browserCheck();
const os=osCheck();
const device=deviceCheck();
$("body").addClass(browser).addClass(os);

const href=$(location).attr('href');
let host=	$(location).attr('hostname');
const ref=document.referrer;
const hash=$(location).attr('hash');

if(href.indexOf("/test/")>0)	{
	host=host + "/test";
}

$(document).ready(function(){
	//init
	$(window).scrollTop(0);
	
 
  //Menu
  if(device=="sp" || device=="tab"){
	  $("#menu-btn").on("click",function(){
		  var scroll;
		  $("#menu").toggleClass("open");
		});
	}
  
  
  var current;
  
  if(device!="sp"){
	  $.scrollify({
		  section : '.js-section', // 対象を指定
		  easing: "swing", // イージングをしてい(jQueryのanimation)
		  setHeights:false,
		  scrollSpeed: 1000, // スクロール時の速度
		  scrollbars: true, // スクロールバーを表示するか
		  //updateHash: false,// アドレスバーにセクションを表示しない
		  before:function(i,box){
	        current = i;
	    },
		});
	  $(window).on('resize',function(){
		    if(current){
	        var currentScrl = $('.js-section').eq(current).offset().top;
	        $(window).scrollTop(currentScrl);
		    }
		});
	  
	  $("#gnavi a").on("click",function(){
		  $.scrollify.move($(this).attr("href"));
		  $("#menu").removeClass("open");
	  });
	}else{
		$(".js-section").each(function(){
			$(this).attr("id",$(this).data("section-name"));
		});
		$("#gnavi a").on("click",function(){
			$("#menu").removeClass("open");
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
	  		let $target = jQuery(this.hash);
	      $target = $target.length && $target || jQuery('[name="' + this.hash.slice(1) +'"]');
	      if ($target.length) {
	      	const targetOffset = $target.offset().top;
	        $('html,body').animate({ scrollTop: targetOffset }, 800, 'easeOutExpo');//fast
	        return false;
	      }
	    }
	  });
	}
	$(".gnavi-top").addClass("active");  
  
  //scroll
  var now="";
  $(window).scroll(function () {
   	const body = window.document.body;
	 	const html = window.document.documentElement;
	 	const scrTop = body.scrollTop || html.scrollTop;
	 	const scrBottom = scrTop + html.clientHeight;
	 	const vh=h/100;
	 	let spOffset=0;
	 	
	 	if(device=="sp"){
		 	spOffset=800;
	 	}
		if(scrTop > $(".id-top").offset().top ){ //messageのアニメーション
			$(".cake-line").removeClass("is-show");
		 	$(".top-logo").removeClass("is-glow").addClass("is-move");
			$(".message-box").addClass("is-show");
		}else{
			$(".cake-line").addClass("is-show");
		 	$(".top-logo").removeClass("is-move").addClass("is-glow");
			$(".message-box").removeClass("is-show");
		}
		
		if(scrTop > $(".id-message").offset().top){//aboutのアニメーション
			$(".about-box").addClass("is-show");
			
			$(".id-message").addClass("about");
		}else{
			
			$(".about-box").removeClass("is-show");
			
			$(".id-message").removeClass("about");
		}
		if(scrTop > $(".id-message").offset().top + spOffset){//aboutのアニメーション
			$("header").addClass("is-white");
			$(".top-logo").addClass("is-color");
			$(".id-message").addClass("about-sp");
		}else{
			$("header").removeClass("is-white");
			$(".top-logo").removeClass("is-color");
			$(".id-message").removeClass("about-sp");
		}
			
		if(scrTop > $(".id-about").offset().top){//gallery1のアニメーション
			$("header").addClass("is-expand");
			$(".top-logo").addClass("is-out");
			$(".about-box").addClass("is-out");
			$(".gallery-box1").addClass("is-show");
			$(".gallery-logo").addClass("is-show");
			
			$(".id-message").addClass("gallery");
			$(".bg1").addClass("gallery");
			
		}else{
			$("header").removeClass("is-expand");
			$(".top-logo").removeClass("is-out");
			$(".about-box").removeClass("is-out");
			$(".gallery-box1").removeClass("is-show");
			$(".gallery-logo").removeClass("is-show");
			
			$(".id-message").removeClass("gallery");
			$(".bg1").removeClass("gallery");
		}
		
		
		if(scrTop > $(".id-gallery1").offset().top ){//gallery2のアニメーション
			$(".gallery-box1").addClass("is-out");
			$(".gallery-logo").addClass("is-shadow");
			$(".gallery-box2").addClass("is-show");
			
			$(".bg1").addClass("sp-gallery");
			$(".bg2").addClass("gallery2");
		}else{
			$(".gallery-box1").removeClass("is-out");
			$(".gallery-logo").removeClass("is-shadow");
			$(".gallery-box2").removeClass("is-show");
			
			$(".bg1").removeClass("sp-gallery");
			$(".bg2").removeClass("gallery2");
		}
		if(scrTop > $(".id-gallery2").offset().top ){//factory1のアニメーション
			$(".gallery-logo").addClass("is-out");
			$(".bg2").addClass("factory1");
		}else{
			$(".gallery-logo").removeClass("is-out");
			$(".bg2").removeClass("factory1");
		}
		
		
		if(scrTop > $(".id-factory1").offset().top ){//factory2のアニメーション
			$(".bg2").addClass("sp-factory");
			$(".bg3").addClass("factory2");
			
		}else{
			$(".bg2").removeClass("sp-factory");
			$(".bg3").removeClass("factory2");
		}
		if(scrTop > $(".id-feature2").offset().top ){//feature3のアニメーション
			$(".bg3").addClass("feature3");
		}else{
			$(".bg3").removeClass("feature3");
		}
		
		if(scrTop > $(".id-feature3").offset().top ){//recruitのアニメーション
			$(".bg3").addClass("recruit");
		}else{
			$(".bg3").removeClass("recruit");
		}
		if(scrTop > $(".id-feature3").offset().top - 55 ){//お菓子教室の背景固定（sp）
			$(".bg4").addClass("sp-feature3");
		}else{
			$(".bg4").removeClass("sp-feature3");
		}
		if(scrTop > $(".id-recruit").offset().top - 55 ){//recruitの背景固定（sp）
			$(".bg5").addClass("sp-recruit");
		}else{
			$(".bg5").removeClass("sp-recruit");
		}
		if(scrTop > $(".id-company").offset().top - 55 ){//companyの背景固定（sp）
			$(".bg6").addClass("sp-company");
		}else{
			$(".bg6").removeClass("sp-company");
		}
		//ナビゲーションの現在地
		$("#gnavi li").removeClass("active")
		if(scrTop >= 0 && scrTop < $(".id-about").offset().top ){
			$(".gnavi-top").addClass("active");
		}else if(scrTop >= $(".id-about").offset().top && scrTop < $(".id-gallery1").offset().top){
			$(".gnavi-about").addClass("active");
		}else if(scrTop >= $(".id-gallery1").offset().top && scrTop < $(".id-factory1").offset().top){
			$(".gnavi-gallery").addClass("active");
		}else if(scrTop >= $(".id-factory1").offset().top && scrTop < $(".id-feature1").offset().top){
			$(".gnavi-factory").addClass("active");
		}else if(scrTop >= $(".id-feature1").offset().top && scrTop < $(".id-recruit").offset().top){
			$(".gnavi-feature").addClass("active");
		}else if(scrTop >= $(".id-recruit").offset().top && scrTop < $(".id-company").offset().top){
			$(".gnavi-recruit").addClass("active");
		}else if(scrTop >= $(".id-company").offset().top){
			$(".gnavi-company").addClass("active");
		}
		
	});
  
  	
	
});

function deviceFunc(w){
	const bp = [559,959,1280];
	

	if(w > bp[1]){ //タブレット以上（PC）

  }else{ //タブレット以下
		
  }
  if (w > bp[0]){//スマートフォン以上
	  $(".tel-wrap .tel-number").each(function(){
			$(this).unwrap();
		});
  }else{ //スマートフォン以下
	 	$(".tel-number").each(function(){
			if(!$(this).parent(".tel-wrap").length){
				const no = $(this).data("tel");
				$(this).wrap('<a href="tel:' + no + '" class="tel-wrap"></a>');
			}
		});	
  }
}

// スクロール禁止
function no_scroll() {
  document.addEventListener("mousewheel", scroll_control, { passive: false });// PCでのスクロール禁止
  document.addEventListener("touchmove", scroll_control, { passive: false });// スマホでのタッチ操作でのスクロール禁止
}
// スクロール禁止解除
function return_scroll() {
  document.removeEventListener("mousewheel", scroll_control, { passive: false });// PCでのスクロール禁止解除
  document.removeEventListener('touchmove', scroll_control, { passive: false });// スマホでのタッチ操作でのスクロール禁止解除
}

// スクロール関連メソッド
function scroll_control(event) {
    event.preventDefault();
}

/*ブラウザ判別*/
function browserCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	const av = window.navigator.appVersion.toLowerCase();
	if(ua.indexOf('edge') != -1){
		return 'edge';
	}else if (ua.indexOf('opera') != -1) {
  	return 'opera';
	}else if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
		$("body").addClass('ie');
  	if (av.indexOf("msie 6.") != -1) {
    	return 'ie6';
  	} else if (av.indexOf("msie 7.") != -1) {
    	return 'ie7';
  	} else if (av.indexOf("msie 8.") != -1) {
    	return 'ie8';
  	} else if (av.indexOf("msie 9.") != -1) {
    	return 'ie9';
  	}else if (av.indexOf("msie 10.") != -1) {
    	return 'ie10';
  	}else if (av.indexOf("rv:11.") != -1) {
    	return 'ie11';
  	}
	} else if (ua.indexOf('chrome') != -1) {
  	return 'chrome';
	} else if (ua.indexOf('safari') != -1) {
  	return 'safari';
	} else if (ua.indexOf('gecko') != -1) {
  	return 'firefox';
	} else {
  	return false;
	}
}

/*OS判別*/
function osCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	if (ua.indexOf('windows') != -1) {
		if(ua.indexOf('phone') != -1){
			return 'winphone';
		}else{
			return 'win';
		}
	}else if(ua.indexOf('iphone') != -1) {
		return 'iphone';
	}else if(ua.indexOf('ipad') != -1) {
		return 'ipad';
	}else if(ua.indexOf('mac') != -1) {
		return 'mac';
	}else if(ua.indexOf('android') != -1) {
		if(ua.indexOf('mobile') != -1){
			if(ua.indexOf('A1_07') != -1){
				return 'tablet';
			}else if(ua.indexOf('SC-01C') != -1){
				return 'tablet';
			}else{	return 'android';}
		}else{
			return 'tablet';
		}
	}else{
		return false;
	}
}

/*デバイス判別*/
function deviceCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	if((ua.indexOf("windows") != -1 && ua.indexOf("touch") != -1 && ua.indexOf("tablet pc") == -1) 
      || ua.indexOf("ipad") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1)
      || ua.indexOf("kindle") != -1
      || ua.indexOf("silk") != -1
      || ua.indexOf("playbook") != -1){
      return 'tab';
    }else if((ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1)
      || ua.indexOf("iphone") != -1
      || ua.indexOf("ipod") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1)
      || ua.indexOf("blackberry") != -1){
	    return 'sp';
    }else{
      return 'pc';
    }
}